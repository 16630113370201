<template>
  <div>
    <b-card-actions
      no-actions
      no-body
      title="Invitations"
    >
      <b-table
        id="invitations_table"
        hover
        class="table_lessons"
        responsive="sm"
        :items="invitations"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(create_date)="row">
          {{ moment(row.item.create_date).format('MMM Do YYYY') }}
        </template>
        <template #cell(send_date)="row">
          {{ moment(row.item.send_date).format('MMM Do YYYY') }}
        </template>
        <template #cell(confirmed_date)="row">
          {{ row.item.confirmed_date ? moment(row.item.confirmed_date).format('MMM Do YYYY') : '' }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        align="center"
        :total-rows="rows_invitations"
        :per-page="perPage"
        aria-controls="invitations_table"
      />
    </b-card-actions>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { BTable, BPagination } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BCardActions,
    BPagination,
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      invitations: [],
      fields: [
        {
          key: 'create_date',
          label: 'Create Date',
          sortable: true,
        },
        {
          key: 'send_date',
          label: 'Send Date',
          sortable: true,
        },
        {
          key: 'number',
          label: 'Ticket number',
          sortable: true,
        },
        {
          key: 'confirmed_date',
          label: 'Confirmed Date',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    rows_invitations() {
      return this.invitations.length
    },
  },
  created() {
    this.$http.get('/api/invitations/invitations', {
    }).then(res => {
      // ? const data = JSON.stringify(res.data)
      // ? console.log('refresh tickets')
      // ? console.log(data)
      this.invitations = res.data
    })
  },
}
</script>
